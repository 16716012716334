import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AirHumidityPipe } from './air-humidity.pipe';
import { FarmPortalWeatherStationsReplaceLinkPipe } from './farm-portal-weather-stations-replace-link.pipe';
import { MessageTypeIconPipe } from './message-type-icon.pipe';
import { TemperaturePipe } from './temperature.pipe';
import { TimePipe } from './time.pipe';
import { PlusOrMinusPipe } from './plus-or-minus.pipe';
import { AddPlusPipe } from './add-plus.pipe';
import { LongDateTimePipe } from './long-date-time';
import { ShortDatePipe } from './short-date';
import { MessageDatePipe } from './message-date';
import { ToNumberPipe } from './to-number.pipe';
import { ApplicationDictionaryPipe } from './application-dictionary.pipe';
import { NanDecimalPipe } from './nan-decimal.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import { FormatPipe } from './format.pipe';
import { PointsPipe } from './points.pipe';

const pipes = [
  AirHumidityPipe,
  FarmPortalWeatherStationsReplaceLinkPipe,
  MessageTypeIconPipe,
  TemperaturePipe,
  TimePipe,
  PlusOrMinusPipe,
  AddPlusPipe,
  LongDateTimePipe,
  ShortDatePipe,
  MessageDatePipe,
  ToNumberPipe,
  ApplicationDictionaryPipe,
  NanDecimalPipe,
  DefaultValuePipe,
  FormatPipe,
  PointsPipe
];

@NgModule({
  declarations: [pipes],
  imports: [CommonModule],
  exports: [pipes]
})
export class PipesModule {}
